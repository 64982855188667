import * as React from "react"
import {Flex, Tabs} from "antd";
import CodeBlock from "./shared/CodeBlock";

const CodeExample = ({ codeCase }) => (
        <Flex gap="middle" vertical>
            <CodeBlock>{codeCase.input}</CodeBlock>
            <CodeBlock input={false}>{codeCase.output}</CodeBlock>
        </Flex>
    );

const ExpectationDetails = ({ codeExamples }) => {
    const tabItems = [
        {
            key: "1",
            label: "Passing case",
            children: <CodeExample codeCase={codeExamples.passingCase}/>
        },
        {
            key: "2",
            label: "Failing case",
            children: <CodeExample codeCase={codeExamples.failingCase}/>
        }
    ];

    return (
        <Tabs defaultActiveKey="1" items={tabItems}/>
    );
};

export default ExpectationDetails;
